<template>
  <div>
    <v-container v-if="progress" type="image" class="loding">
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </v-container>
    <div v-else>
    <form @submit.prevent="submitForm">
      <div class="row mt-4">
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Information technique</div>
            </div>
          </div>

          <div class="center borderPointier mt-3 p-3">
            <b-form-group label="Type de pompe ">
              <b-form-select
                v-model="systemeChauffageData.denomination_temperature"
                :options="optionsType"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-select>
            </b-form-group>

            <div class="fieldset mt-3">
              <div class="legend mb-2">Coeffcient de performance (COP)</div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.coefficient_performance"
                placeholder="Coeffcient "
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">
                Efficacité énergétique saisonniére en % (calculer selon le
                réglément (EU))
              </div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.efficacite"
                placeholder="Efficacité"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">Surface chauffée(PAC)</div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.surface_chauffee"
                placeholder="Surface"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </div>

            <b-form-group label="Régulateur">
              <b-form-select
                v-model="systemeChauffageData.regulateur_classer"
                :placeholder="$t('Régulateur')"
                :options="optionsTypeClient"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Matériel depose</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group label="Équipement">
              <b-form-input
                type="text"
                v-model="systemeChauffageData.equipement_deposee"
                placeholder="Équipement"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Type de combustible">
              <b-form-input
                type="text"
                placeholder=" type "
                v-model="systemeChauffageData.type_energie_equipement_deposee"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </form>
  </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        Précédent
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        Suivant
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
    data() {
      return {
        form: {},
  
        systemeChauffageData: null,
        systemAdd: false,
        optionsType: [
          { value: "moyenne", text: this.$t("Moyenne") },
          { value: "haute", text: this.$t("Haute") },
          { value: "basse", text: this.$t("Basse") },
        ],
        optionsTypeClient: [
          { value: "I", text: this.$t("I") },
          { value: "II", text: this.$t("II") },
          { value: "III", text: this.$t("III") },
          { value: "IV", text: this.$t("IV") },
          { value: "V", text: this.$t("V") },
        ],
      };
    },
    computed: {
    ...mapGetters([
      "getdevisMonoFiche",
      
    ]),},
    created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.systemeChauffageData = this.initializeSystemeChauffageData();
    
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.systemeChauffageData = this.initializeSystemeChauffageData();
          this.systemAdd = newDevis.data?.devis.systeme_chauffage !== null;
    
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
      ...mapActions(["editSystemeChauffage", "storeSystemeChauffage","setDevisMonoFiche"]),
  
      initializeSystemeChauffageData() {
        const systemeChauffage =
          this.getdevisMonoFiche?.data?.devis?.systeme_chauffage || {};
        return {
          denomination_temperature:
            systemeChauffage.denomination_temperature || "",
          coefficient_performance:
            parseInt(systemeChauffage.coefficient_performance) || "",
          efficacite: parseInt(systemeChauffage.efficacite) || "",
          cp: systemeChauffage.cp || "",
          surface_chauffee: parseInt(systemeChauffage.surface_chauffee) || "",
          regulateur_classer: systemeChauffage.regulateur_classer,
          equipement_deposee: systemeChauffage.equipement_deposee,
          type_energie_equipement_deposee:
            systemeChauffage.type_energie_equipement_deposee,
        };
      },
      async submitForm() {
        const response = await this.storeSystemeChauffage(
          this.systemeChauffageData
        );
        this.getdevisMonoFiche.data.devis.systeme_chauffage_id = response.data.id;
        this.getdevisMonoFiche.data.devis.systeme_chauffage={
          id:response.data.id,
          denomination_temperature:this.systemeChauffageData.denomination_temperature,
          coefficient_performance:this.systemeChauffageData.coefficient_performance,
          efficacite:this.systemeChauffageData.efficacite,
          cp:this.systemeChauffageData.cp,
          surface_chauffee:this.systemeChauffageData.surface_chauffee,
          regulateur_classer:this.systemeChauffageData.regulateur_classer,
          equipement_deposee:this.systemeChauffageData.equipement_deposee,
          type_energie_equipement_deposee:this.systemeChauffageData.type_energie_equipement_deposee,
        }
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      },
      async edit() {
 
        if (!this.systemAdd) {
          await this.submitForm();
          console.log(' this.getdevisMonoFiche.data.devis.systeme_chauffage', this.getdevisMonoFiche.data.devis.systeme_chauffage)

        } else {
          const systeme = {
            ...this.systemeChauffageData,
            id: this.getdevisMonoFiche.data?.devis?.systeme_chauffage.id,
          };
  
          const res = await this.editSystemeChauffage(systeme);
          this.getdevisMonoFiche.data.devis.systeme_chauffage={
          id:this.getdevisMonoFiche.data?.devis?.systeme_chauffage.id,
          denomination_temperature:this.systemeChauffageData.denomination_temperature,
          coefficient_performance:this.systemeChauffageData.coefficient_performance,
          efficacite:this.systemeChauffageData.efficacite,
          cp:this.systemeChauffageData.cp,
          surface_chauffee:this.systemeChauffageData.surface_chauffee,
          regulateur_classer:this.systemeChauffageData.regulateur_classer,
          equipement_deposee:this.systemeChauffageData.equipement_deposee,
          type_energie_equipement_deposee:this.systemeChauffageData.type_energie_equipement_deposee,
        }
          this.setDevisMonoFiche(this.getdevisMonoFiche);
          console.log(' this.getdevisMonoFiche.data.devis.systeme_chauffage', this.getdevisMonoFiche.data.devis.systeme_chauffage)

          return res;
        }
      },
      async goToNextStep() {
      await this.edit();
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 4);
    },
    },
  };
  </script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
