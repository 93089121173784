<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devis171/stepperGeneric.vue';
import stepAdresseBar171 from '../../components/stepper/devis171/stepAdresseBar171.vue';
import stepChaniterBar171 from '../../components/stepper/devis171/stepChaniterBar171.vue';
import stepClientBar171 from '../../components/stepper/devis171/stepClientBar171.vue';
import stepGeneraliteBar171 from '../../components/stepper/devis171/stepGeneraliteBar171.vue';
import stepInformationBar171 from '../../components/stepper/devis171/stepInformationBar171.vue';
import stepInstallateurBar171 from '../../components/stepper/devis171/stepInstallateurBar171.vue';
import stepReglementBar171 from '../../components/stepper/devis171/stepReglementBar171.vue';
import stepDevisBar171 from '../../components/stepper/devis171/stepDevisBar171.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteBar171 },
        { label: 'Client', component: stepClientBar171 },
        { label: 'Installateur', component: stepInstallateurBar171 },
        { label: 'Adresse', component: stepAdresseBar171 },
        { label: 'Information', component: stepInformationBar171 },
        { label: 'Chantier', component: stepChaniterBar171 },
        { label: 'Règlement', component: stepReglementBar171 },
        { label: 'Devis', component: stepDevisBar171 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>