<template>
  <div>
  <div>
    <div class="modal-test">
      <v-chip
        class="py-4 px-4 brouillon"
        v-if="getdevisMonoFiche.data.devis.brouillon === 0"
        style="background: #e4261b; color: white"
      >
        {{ $t("DRAFT") }}
      </v-chip>
      <v-chip
        class="py-3 px-4 brouillon"
        v-if="getdevisMonoFiche.data.devis.brouillon === 1"
        style="background: #f8f8f8"
      >
        {{ $t("SIGNA") }}
      </v-chip>

      <div class="row">
        <div class="col-8 d-flex">
          <div>
            <b>{{ $t("ESTIMATE") }} </b>
          </div>
          <span class="info">N° {{ getdevisMonoFiche.data.devis?.numerodevis }}</span>
        </div>
        <div class="col-4 d-flex">
          <span class="info d-none d-md-block">
            {{ getdevisMonoFiche.data.devis?.client?.prenom }}</span
          >
        </div>
      </div>
      <div class="part justify">
        <div class="divborder"></div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <div class="block">
              <div class="d-flex">
                <span class="title"
                  >{{ $t("Numéro client") }}:
                  {{ getdevisMonoFiche.data?.devis?.client?.id }}
                </span>
                <span>
                  {{ getdevisMonoFiche.data?.client?.nom }}
                  {{ getdevisMonoFiche.data.devis?.client?.prenom }}
                </span>
                <span> {{ getdevisMonoFiche.data?.client?.nom }} </span>
              </div>
              <div class="d-flex">
                <span class="title">{{ $t("DATE_VISITE_TECHNIQUE") }}:</span>
                <span>
                  {{ getdevisMonoFiche.data?.devis?.visite_technique }}</span
                >
              </div>
              <div class="d-flex">
                <span class="title">{{ $t("DATE_DEVIS") }}:</span>
                <span> {{ getdevisMonoFiche.data?.devis?.debut_devis }} </span>
              </div>
              <div class="d-flex">
                <span class="title">{{ $t("DATE-FIN") }}:</span>
                <span> {{ getdevisMonoFiche.data?.devis?.fin_devis }} </span>
              </div>
              <div class="d-flex">
                <span class="title">{{ $t("Adresse des travaux") }}:</span>
                <span>
                  {{ getdevisMonoFiche.data?.devis?.rue_travaux }}
                  {{ getdevisMonoFiche.data?.devis?.ville_travaux }}
                  {{ getdevisMonoFiche.data?.devis?.cp_travaux }}
                </span>
              </div>
              <div>
                parcelle cadastrale:
                {{
                  getdevisMonoFiche.data.devis?.logement?.parcelle_cadastrale
                }}
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <ul>
              <span class="info">
                {{ getdevisMonoFiche.data?.devis?.client?.rue }}
              </span>
              <span class="info">
                {{ getdevisMonoFiche.data?.devis?.client?.cp }}
                {{ getdevisMonoFiche.data?.devis?.client?.ville }}
              </span>
              <li>
                <span class="entrepriseinfo">{{ $t("TEL") }} : </span>
                <span>
                  {{ getdevisMonoFiche.data.devis?.client?.phone.prefix }}
                  {{ getdevisMonoFiche.data.devis?.client?.phone.phone_number }}
                </span>
              </li>
              <li>
                <span class="entrepriseinfo">{{ $t("EMAIL") }} : </span>
                <span>
                  {{ getdevisMonoFiche.data?.devis?.client?.email }}
                </span>
              </li>
              <li>
                <span class="entrepriseinfo">{{ $t("ZONE") }} : </span>
                <span>{{ getdevisMonoFiche.data?.devis?.client?.zone }} </span>
              </li>
              <li>
                <span class="entrepriseinfo"
                  >{{ $t("Type de chauffage") }} :
                </span>
                <span>
                  {{ getdevisMonoFiche.data?.devis?.logement?.type_chauffage }}
                </span>
              </li>
              <li>
                <span class="entrepriseinfo"
                  >{{ $t("Type de logement") }} :
                </span>
              </li>
              <li>
                <span>
                  {{ getdevisMonoFiche.data?.devis?.logement?.type }}
                </span>
                <span
                  >/+
                  {{
                    getdevisMonoFiche.data?.devis?.logement?.ancienter
                  }}
                  ans</span
                >
              </li>
              <li>
                <span
                  >/{{
                    getdevisMonoFiche.data?.devis?.logement?.surface
                  }}
                  m²</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div class="d-flex">
        <div class="flex-grow-1">
          <b>{{ $t("DETAILS_DEVIS") }} </b>
        </div>
        <div class="flex-grow-1">
          <div class="d-flex justify-content-around">
            <div>
              <b>{{ $t("QTE") }} </b>
            </div>
            <div>
              <b>{{ $t("PUHT") }}</b>
            </div>
            <div>
              <b>{{ $t("TOTALHT") }}</b>
            </div>
            <div>
              <b>{{ $t("VAT") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div>
        BAR-TH-171 : Mis en page d'une pompe à chaleur de type air/eau,
        <b>{{
          getdevisMonoFiche.data.devis?.systeme_chauffage?.denomination_temperature
        }} </b
        > temperature.
      </div>
      <div>
        Le coefficient de performance (COP) est de :
        <b>{{
          getdevisMonoFiche.data?.devis?.systeme_chauffage?.coefficient_performance
        }}</b>
      </div>
      <div>
        L'efficacité énergitique saisonnière est de :
        <b>{{ getdevisMonoFiche.data?.devis?.systeme_chauffage?.efficacite }} %</b>
        calculée selon le règlement (EU)
      </div>
      <div>n°813/2013 de la commission du 2 aout 2013</div>
      <div>
        La surface chauffée par la PAC est de
        <b>{{
          getdevisMonoFiche.data?.devis?.systeme_chauffage?.surface_chauffee
        }}</b>
      </div>
      <div
        v-for="(chantierItem, index) in getdevisMonoFiche.data
          .GroupeLigneDocument_produit"
        :key="index"
      >
        <div>
          <div v-if="chantierItem.type_ligne === 'produit'">
            <div>
              Marque :
              {{ chantierItem.fabricant }}
            </div>
            <div>
              Réf :
              {{ chantierItem.ref }}
            </div>
          </div>
        </div>
      </div>
      <div>
        Mise en place d'un régulateur de classe :
        <b>{{
          getdevisMonoFiche.data?.devis?.systeme_chauffage?.regulateur_classe
        }}</b>
      </div>
      <div></div>
      <ul>
        <li>
          Dépose et remplacement d'une chaudière
          <b>{{
            getdevisMonoFiche?.data?.devis?.systeme_chauffage?.equipement_deposee
          }}</b>
        </li>
        <li>
          Kwh Cumac : <b>{{ getdevisMonoFiche.data?.devis?.cumac }}</b>
        </li>
        <li>Prime Coup de pouce CEE : <b> {{getdevisMonoFiche?.data?.devis?.montant_cee}} </b></li>
      </ul>
      <div
        v-if="getdevisMonoFiche.data.groupeLigneDocument[0]?.sous_traitant !== null"
      >
        Traveaux sous-traités auprès de l'entreprise
        <b>
          {{ getdevisMonoFiche.data.groupeLigneDocument[0]?.sous_traitant?.name }}</b
        >
      </div>
      <div v-else>
        Matériel(s) fourni(s) et mis en place par notre société
        <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
      </div>
      <div
        v-if="getdevisMonoFiche.data.groupeLigneDocument[0]?.sous_traitant !== null"
      >
        représentée par
        {{ getdevisMonoFiche.data.groupeLigneDocument[0]?.sous_traitant?.name }} ,
        SIRET
        <b>
          {{
            getdevisMonoFiche.data.groupeLigneDocument[0]?.sous_traitant?.num_siret
          }}
          ,</b
        >
        Certificat rge
      </div>
      <div v-else>
        représentée par
        {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
        <b>
          {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
          ,</b
        >
        Certificat rge
      </div>
      <div>
        Numéro
        <b> {{ getdevisMonoFiche.data?.devis?.certificat_rge?.numero }} </b> attribué
        le <b>{{ getdevisMonoFiche.data?.devis?.debut_travaux }}</b> valable jusqu'au
        <b> {{ getdevisMonoFiche.data?.devis?.fin_travaux }}</b
        >, Assurance
      </div>
      <div>
        civile
        <b> N° {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b>
      </div>
      <ul>
        <li>
          Date de la visite technique :<b>
            {{ getdevisMonoFiche.data?.devis?.visite_technique }}</b
          >
        </li>
      </ul>
      <div>
        <div
          v-for="(produit, idx) in getdevisMonoFiche.data.GroupeLigneDocument_produit"
          :key="idx"
          class="row mb-2"
        >
          <div class="col-md-6">
            <div>
              <b>{{ produit.lib }}</b>
            </div>
            <p>{{ produit.desc }}</p>
          </div>

          <div class="col-md-6">
            <div class="row text-center">
              <div class="col">
                <b>{{ produit.quantite }} u</b>
              </div>
              <div class="col">
                <b>{{ produit.unit_price }} {{ produit.type_unite }}</b>
              </div>
            
              <div class="col">
                <b>{{ parseFloat(produit.quantite) * parseFloat(produit.unit_price) }} €</b>
              </div>
              <div class="col">
                <b>{{ produit.tva }} %</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="part"></div>
      </div>

      <div class="part">
        <div><b>Termes et conditions CEE</b></div>
        <p>
          Les travaux ou prestations objet du présent document donneront lieu à
          une contribution financiére de
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }} (SIRET
          {{ getdevisMonoFiche.data.devis?.partenaire?.siret }}), versée par
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }} dans le cadre de son
          role actif et incitatif sous forme, directement ou via son mondataire
          {{ getdevisMonoFiche.data.devis?.mandataire?.nom }} sous réserve de
          l'engagement de fournir excclusivement à
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }} les documents
          nécessaires à la valorisation des opérations au titre du dispositif
          des Certificats d'Economies d'Energie et sous réserve de la validation
          de l'éligibilité du dossier par
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }} puis par l'autorité
          administrative compétente. Le montant de cette contribution
          financiére, hors champ d'application de la TVA, est susceptible de
          varier en fonction des travaux effectivement réalisé et du volume des
          CEE attribués à l'opération et est estimé à 4 000,00 € (*)
        </p>
        <div></div>
        <p>
          Le client accepte que {{ onlineUser.entreprise.lib }} collecte et
          traite ses données à caractére personnel pour les besoins du dépot
          d'un dossier CEE sur le registre EMMY conformément aux dispositions
          réglementaires en vigueur et qu'il communique ces données à
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }} à des fins de controle
          de la conformité des operations réalisées chez le client
          {{ getdevisMonoFiche.data.devis?.partenaire?.nom }}
          s'engage à respecter la réglementation française et européenne
          relative à la protection des données à caractére personnel Le
          bénéficiaire renconnait avoir pris connaissance et accepter les
          conditions générales de vente figurant ci-jointes.
        </p>
        <div>
          <div class="cadre row">
            <div class="col-8">
              <div>
                Apposer signature précédée de la mention "Bon pour accord"
              </div>
              <div>Le :</div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <div class="col-6">Total H.T</div>
                <div class="col-6">{{ getdevisMonoFiche.data.total_HT }}</div>
              </div>
              <div class="row">
                <div class="col-6">Total TVA</div>
                <div class="col-6">{{ getdevisMonoFiche.data?.total_TVA }}</div>
              </div>
              <div class="row">
                <div class="col-6"><b>Total TTC</b></div>
                <div class="col-6">
                  {{ getdevisMonoFiche.data.groupeLigneDocument[0]?.total_TTC }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">PrimeCEE *</div>
                <div class="col-6">
                  {{ getdevisMonoFiche.data?.devis?.montant_cee }}€
                </div>
              </div>
              <div class="row">
                <div class="col-6">Reste à payer</div>
                <div class="col-6">{{ this.calculeRest() }} €</div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            {{ onlineUser.entreprise?.lib }} -
            {{ getdevisMonoFiche.data.devis?.entreprise?.rue }} - au capital de
            {{ getdevisMonoFiche.data.devis?.entreprise?.cp }} Siret :{{
              getdevisMonoFiche.data.devis?.entreprise?.siret
            }}
            - N° {{ getdevisMonoFiche.data?.devis?.entreprise?.num_tva }}
          </div>
          <div class="d-flex justify-content-center">
            {{ getdevisMonoFiche.data.devis?.entreprise?.phone }} Courriel :
            {{ getdevisMonoFiche.data.devis?.entreprise?.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-4">
    <b-button @click="goToPrevStep" class="button-pre-style m-3">
      Précédent
    </b-button>
    <b-button
      type="submit"
      @click="goToNextStep"
      class="button-suivant-style m-3"
    >
      Terminer
    </b-button>
  </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getOnlineUser", "getdevisMonoFiche"]),
    onlineUser() {
      return this.getOnlineUser;
    },
  },
  created() {
    if (this.getdevisMonoFiche.data && this.getdevisMonoFiche.data.devis) {
      this.formatDates();
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler(newVal) {
        if (newVal.data && newVal.data.devis) {
          this.formatDates();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    formatDates() {
      if (this.getdevisMonoFiche.data.devis.visite_technique) {
        this.getdevisMonoFiche.data.devis.visite_technique = new Date(
          this.getdevisMonoFiche.data.devis.visite_technique
        )
          .toISOString()
          .slice(0, 10);
      }
      if (this.getdevisMonoFiche.data.devis.debut_devis) {
        this.getdevisMonoFiche.data.devis.debut_devis = new Date(
          this.getdevisMonoFiche.data.devis.debut_devis
        )
          .toISOString()
          .slice(0, 10);
      }
      if (this.getdevisMonoFiche.data.devis.fin_devis) {
        this.getdevisMonoFiche.data.devis.fin_devis = new Date(
          this.getdevisMonoFiche.data.devis.fin_devis
        )
          .toISOString()
          .slice(0, 10);
      }
    },
    calculeRest() {
      return (
        this.getdevisMonoFiche.data.devis.montant_cee -
        this.getdevisMonoFiche.data.total_TTC
      );
    },
    valideDevis() {
      if (
        this.getdevisMonoFiche.data.devis.client &&
        this.getdevisMonoFiche.data.devis.logement &&
        this.getdevisMonoFiche.data.devis.certificat_rge &&
        this.getdevisMonoFiche.data.GroupeLigneDocument_produit &&
        this.getdevisMonoFiche.data.devis.partenaire &&
        this.getdevisMonoFiche.data.devis.mandataire &&
        this.getdevisMonoFiche.data.devis.debut_devis &&
        this.getdevisMonoFiche.data.devis.fin_devis &&
        this.getdevisMonoFiche.data.devis.moyens_paiement &&
        this.getdevisMonoFiche.data.devis.cp_travaux &&
        this.getdevisMonoFiche.data.devis.visite_technique &&
        this.getdevisMonoFiche.data.devis.rue_travaux &&
        this.getdevisMonoFiche.data.devis.ville_travaux &&
        this.getdevisMonoFiche.data.devis.systeme_chauffage
      ) {
        this.getdevisMonoFiche.data.devis.brouillon = 0;
        this.getdevisMonoFiche.data.brouillon = 0;
      } else {
        this.getdevisMonoFiche.data.devis.brouillon = 1;
        this.getdevisMonoFiche.data.brouillon = 1;
      }
    },
   
    goToNextStep() {
      this.valideDevis();
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      this.$emit("changeStep", 9);
    },
    goToPrevStep() {
      this.$emit("changeStep", 7);
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  font-size: 13px;
}
.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
  height: 3px;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 2px;
  background-color: #000000;
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.brouillon {
  border-radius: 25px;
}
b {
  color: #28367a;
}
.red {
  font-weight: bold;
  color: #e4261a;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }

  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
    height: 350px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .half-width {
    flex: 0 0 25%;
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}

.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
